@media (min-width: 960px) {
  .lift-state-card-events-tab-event {
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .lift-state-card-events-tab-event {
    width: 50%;
  }
}

.lift-state-card-events-tab-event-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-web-grey);
}

.lift-state-card-evens-tab-last-event-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-web-grey);
  margin-bottom: 6px;
}

.lift-state-card-evens-tab-last-event {
  display: flex;
  gap: 2px;
  align-items: center;
}

.lift-state-card-evens-tab-last-event-content {
  width: 100%;
  border-radius: 6px;
  height: 60px;
  padding-right: 22px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(154, 188, 0, 0.11);
}

.lift-state-card-evens-tab-last-event-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.lift-state-card-evens-tab-last-event-info-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--stannah-dark-grey);
}

.lift-state-card-evens-tab-last-event-details {
  text-align: right;
}

.lift-state-card-evens-tab-last-event-details-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-dark-grey);
}

.lift-state-card-evens-tab-last-event-details-info {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--stannah-dark-grey);
}

.lift-state-card-events-tab-history-title {
  padding-top: 24px;

  & > h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--stannah-dark-grey);
    margin-bottom: 3px;
  }

  & > h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--stannah-web-grey);
    margin-top: 0;
  }
}

.lift-state-card-events-tab-history {
  & .list-content {
    border: none;
    position: relative;
    padding-bottom: 0;
    padding-top: 0;
    overflow: hidden;
  }

  & tr {
    display: grid;
    grid-template-columns: 1fr 2fr;

    & td {
      display: flex;
      align-items: center;
    }
  }

  & .list-tools {
    margin-bottom: 0;
  }

  & tbody {
    max-height: 260px;
    table-layout: fixed;
    display: block;
    overflow-y: auto;
  }

  & thead,
  tbody tr {
    width: 100%;
    table-layout: fixed;
  }

  & tbody::-webkit-scrollbar {
    width: 4px;
  }

  & tbody::-webkit-scrollbar-track {
    background: var(--stannah-web-light-grey);
    border-radius: 10px;
  }

  & tbody::-webkit-scrollbar-thumb {
    background: var(--stannah-web-light-grey);
    border-radius: 10px;
    transition: var(--stannah-web-light-grey) 0.3s;
  }

  & tbody::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & tbody:hover::-webkit-scrollbar-thumb,
  tbody:active::-webkit-scrollbar-thumb {
    background: var(--stannah-web-grey);
  }

  & tbody::-webkit-scrollbar-button {
    display: none;
  }
}
