.customer-form {
  height: 100%;

  & form {
    height: 100%;
  }

  & h2 {
    color: var(--stannah-web-grey);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 0px;
    padding-bottom: 15px;
    padding-top: 0px;
  }

  & .adjacent-formfield {
    margin-left: 20px;
  }
}

.customer-form-customer-page {
  display: flex;
  height: 100%;
  flex-direction: column;

  & input[name="id"] {
    width: 200px;
  }

  & input[name="firstName"] {
    width: 336px;
  }

  & input[name="lastName"] {
    width: 336px;
  }

  & input[name="organization"] {
    width: 467px;
  }

  & .c-vat {
    margin-left: 20px;
  }

  & input[name="vat"] {
    width: 200px;
  }

  & input[name="phoneNr"] {
    width: 467px;
  }

  & input[name="email"] {
    width: 467px;
  }

  & input[name="address"] {
    width: 467px;
  }

  & input[name="apartmentNr"] {
    width: 200px;
  }

  & input[name="city"] {
    width: 467px;
  }

  & input[name="zipCode"] {
    width: 200px;
  }

  & input[name="region"] {
    width: 336px;
  }

  & input[name="country"] {
    width: 336px;
  }

  & #submit_button {
    float: right;
    color: var(--stannah-white);
    background-color: var(--stannah-teal);
    margin-left: 20px;
  }
}

.customer-form-inputs {
  flex-grow: 1;
}

.customer-is-accountowner-checkbox {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  & input {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
}

.customer-form-creation-email-row {
  display: flex;
  justify-content: left;
  align-items: center;

  .formfield {
    width: auto;
  }
}

#help-icon-customer-form {
  margin-top: 15px;
  margin-left: 10px;
}

.customer-form-creation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;

  & h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 0px;
    color: var(--stannah-dark-grey);
  }

  & h4 {
    width: 390px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 0px;
    color: var(--stannah-web-grey);
  }

  & button {
    float: right;
  }
}

.customer-form-creation-input-row {
  width: 90%;
  display: flex;
}

.customer-form-contact-users {
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 2fr 0.25fr;

  & input {
    width: 100%;
  }

  & .delete-contact-button {
    margin-top: 20px;
    border: none;
    color: var(--stannah-teal);
    background-color: var(--stannah-white);

    &:hover {
      cursor: pointer;
    }

    & .delete-icon {
      width: 24px;
    }
  }
}

.customer-form-errors {
  flex-grow: 1;
  padding: 20px;
  align-content: end;
  font-size: 14px;
  color: var(--stannah-web-grey);
}

.customer-form-creation-phone-row {
  width: 467px;
}
