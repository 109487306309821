.topmenu {
  color: var(--stannah-black);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--stannah-grey);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & menu {
    list-style-type: none;
    padding-left: 0;
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    & li {
      margin-right: 5px;
      font-size: 20px;
      cursor: pointer;
    }

    & a {
      text-decoration: none;
    }
  }
}

.topmenu-icon {
  width: 24px;
  margin-left: 16px;
  color: var(--stannah-bordeux);
}

.topmenu-icon:hover {
  color: var(--stannah-black);
}
