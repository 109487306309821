.device-state-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  gap: 29px;
  margin-bottom: 24px;
}

.device-state-info-circle-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 50%;
}

.device-state-info-circle-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.device-stateinfo-circle-data {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-dark-grey);
}

.test {
  z-index: 1000;
}

.device-state-info-refresh-button {
  flex-grow: 0;
  flex-shrink: 0;
  background-color: transparent;
  border: 0px;

  & :hover {
    cursor: pointer;
  }

  & svg {
    fill: var(--stannah-dark-grey);
  }
}
