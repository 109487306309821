.side-modal {
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0px;
  margin-left: auto;
  z-index: 10;
  position: relative;
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 0px;
  width: 800px;
  height: 100%;
  background-color: var(--stannah-white);
  box-shadow: -2px 0px 0px 0px #00000033;
}

.side-modal-close {
  position: absolute;
  right: 0px;
  width: 50px;
  height: 50px;
  top: 0px;
  margin: 30px;
  padding: 13px;
  border: none;
  flex-grow: 0;
  background-color: transparent;
  cursor: pointer;
  color: var(--stannah-teal);
}

.side-modal header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.side-modal-title {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  padding-right: 25px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--stannah-bordeux);
}

.side-modal-pager {
  padding-top: 50px;
  width: 100%;
  height: 10px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.side-modal-pager-indicator {
  width: 100%;
  max-width: 80px;
  position: relative;
  border-radius: 10px;
  background-color: var(--stannah-putty);
  height: 10px;
}

.side-modal-pager-indicator-active {
  background-color: var(--stannah-support-orange);
}

.side-modal-content {
  padding-top: 50px;
  padding-right: 60px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.side-modal-content::-webkit-scrollbar {
  width: 6px;
}

.side-modal-content::-webkit-scrollbar-track {
  background: var(--stannah-web-light-grey);
  border-radius: 10px;
}

.side-modal-content::-webkit-scrollbar-thumb {
  background: var(--stannah-web-light-grey);
  border-radius: 10px;
  transition: var(--stannah-web-light-grey) 0.3s;
}

.side-modal-content::-webkit-scrollbar-thumb {
  background: transparent;
}

.side-modal-content:hover::-webkit-scrollbar-thumb,
.side-modal-content:active::-webkit-scrollbar-thumb {
  background: var(--stannah-web-grey);
}

.side-modal-content::-webkit-scrollbar-button {
  display: none;
}
