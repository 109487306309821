.list {
  width: 100%;
}

.list-tools {
  display: flex;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
}

.list-search {
  position: relative;

  & input {
    height: 60px;
    width: 336px;
    font-size: 18px;
    padding: 12px 44px 12px 20px;
    margin-bottom: 12px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 6px;
    color: var(--stannah-web-grey);
    background-color: var(--stannah-white);
    border: 1px solid var(--stannah-web-pale-grey);
    box-sizing: border-box;
  }
}

.arrow {
  all: unset;
  padding-right: 10px;
  padding-left: 10px;
}

.arrow:disabled {
  color: var(--stannah-web-pale-grey);
}

.current-page-number {
  border: 2px solid var(--stannah-dark-grey);
  border-radius: 5px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
}

.total-elements {
  font-size: 16px;
  padding-right: 30px;
  color: var(--stannah-web-grey);
}

.page-pagination {
  margin-top: 15px;
  font-size: 18px;
  float: right;
  display: flex;
  align-items: center;
}

.page-pagination > * {
  margin: 0; /* Removes default margins */
}

.page-pagination select {
  height: 30px; /* Adjust as needed */
  border-radius: 5px;
}

.page-pagination button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px; /* Adjust as needed */
  width: 30px; /* Adjust as needed */
}

.list-search-icon {
  color: var(--stannah-web-grey);
  position: absolute;
  right: 12px;
  top: 16px;
}

.list-buttons {
  flex-grow: 1;
  display: flex;
  justify-content: right;
  gap: 12px;
  padding-right: 12px;

  & button {
    border: 0px;
    background-color: transparent;
    color: var(--stannah-dark-grey);
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    text-align: left;
    cursor: pointer;
  }
}

.list-content {
  width: 100%;
  position: relative;
  border-radius: 6px;
  background-color: var(--stannah-white);
  border: 1px solid var(--stannah-web-pale-grey);
  box-sizing: border-box;
  overflow: visible;
  padding-top: 10px;
  padding-bottom: 12px;
}

.list-content table {
  width: 100%;
  overflow-x: scroll;
  border-collapse: collapse;

  & thead tr {
    margin: 0px;
    border-bottom: 4px solid var(--stannah-dark-grey);
    line-height: 24px;

    & th {
      font-weight: 800;
      padding: 12px 12px 2px 12px;
      text-align: left;
    }

    & th:first-child {
      padding-left: 24px;
    }
  }

  & tbody {
    & tr {
      border-bottom: 1px solid var(--stannah-grey);
    }

    & tr:hover {
      background-color: var(--stannah-web-light-grey);
    }
  }

  & td {
    padding: 12px;
  }

  & td:first-child {
    padding-left: 24px;
  }

  .list-no-data {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  .export-icon {
    color: var(--stannah-web-grey);
  }
}
