.device-info-card-state {
  margin-right: 20px;
  margin-left: 20px;
}

.device-info-card-title-section {
  display: flex;
  align-items: space-between;
  width: 100%;
  max-width: 437px;
  max-height: 179px;

  & .device-info-card-title-section-image {
    padding: 12px 38px;
    background: var(--stannah-web-light-grey);
    border-radius: 6px;
    margin-right: 24px;
  }

  & .formfield {
    margin-bottom: 0;
  }

  & .device-info-card-title-section-content {
    display: grid;
    grid-template-rows: 2fr 1fr 2fr;

    & .device-info-card-title-section-row {
      max-width: 235px;
      width: 100%;

      & .device-info-card-title-section-title {
        color: var(--stannah-dark-grey);
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-top: 0px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    & .device-info-card-title-section-subscription-state {
      margin: 10px 0;
    }
  }
}

.device-info-card-info {
  margin-bottom: 32px;
}

.device-info-card-info-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--stannah-dark-grey);
}

.device-info-card-info-data {
  padding-left: 24px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--stannah-mid-grey);
  padding-top: 12px;
}

.device-info-card-info-data-location {
  display: flex;
  gap: 24px;

  & svg {
    fill: var(--stannah-web-grey);
  }
}
