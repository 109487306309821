.phone-number-field-container {
  margin-bottom: 5px;
}

.phone-number-field {
  border: 2px solid var(--stannah-mid-grey);
  border-radius: 8px;
  display: flex;
  height: 60px;
  width: 100%;
}

.phone-number-field.disabled {
  border: 2px solid transparent;
  background-color: var(--stannah-web-light-grey);
  cursor: not-allowed;
}

.phone-number-field.error {
  border: 2px solid red;
}

.phone-number-field-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-align: left;
  color: var(--stannah-dark-grey);
  margin-bottom: 5px;
}

.phone-number-field-label.disabled {
  color: var(--stannah-mid-grey);
}

.phone-number-field-picker {
  width: 98px;
  height: 100%;
  overflow: visible;
  font-size: 18px;
  position: relative;
}

.phone-number-field-picker-button {
  background-color: transparent;
  border: none;
  text-align: left;
  color: var(--stannah-mid-grey);
  padding: 16px 0px 16px 20px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  gap: 10px;

  &:focus {
    outline: none;
  }

  & svg {
    color: var(--stannah-dark-grey);
  }
}

.phone-number-field-options-list {
  display: none;
  text-align: left;
  width: 100%;
  border: 2px solid var(--stannah-dark-grey);
  background-color: #fff;
  border-radius: 8px;
  min-width: 160px;
  z-index: 1;
  position: absolute;
  max-height: 460px;
  height: auto;
  overflow-y: auto;

  & input {
    height: 30px;
    width: 100%;
    border-radius: 0;
    border: none;
    padding: 12px 16px;
    border-bottom: 1px solid var(--stannah-mid-grey);
    color: var(--stannah-mid-grey);
    font-size: 16px;
    font-weight: 400;
  }

  & .phone-number-field-options-list-no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
    color: var(--stannah-bordeux);
  }
}

.phone-number-field-options-list::-webkit-scrollbar {
  width: 6px;
}
.phone-number-field-options-list::-webkit-scrollbar-track {
  background: var(--stannah-web-light-grey);
  border-radius: 10px;
}
.phone-number-field-options-list::-webkit-scrollbar-thumb {
  background: var(--stannah-web-light-grey);
  border-radius: 10px;
  transition: var(--stannah-web-light-grey) 0.3s;
}
.phone-number-field-options-list::-webkit-scrollbar-thumb {
  background: transparent;
}
.phone-number-field-options-list:hover::-webkit-scrollbar-thumb,
.phone-number-field-options-list:active::-webkit-scrollbar-thumb {
  background: var(--stannah-web-grey);
}
.phone-number-field-options-list::-webkit-scrollbar-button {
  display: none;
}

.phone-number-field-options-list button {
  color: var(--stannah-black);
  background-color: #fff;
  width: 100%;
  text-align: left;
  border-radius: 8px;
  padding: 12px 16px;
  text-wrap: none;
  text-decoration: none;
  display: block;
  border: none;

  &:disabled {
    display: none;
  }
}

.phone-number-field-picker button[disabled] {
  cursor: not-allowed;
}

.phone-number-field-options-list button:hover {
  background-color: var(--stannah-bordeux);
  color: #fff;
}

.phone-number-field-picker:hover .phone-number-field-options-list {
  display: block;
}

.phone-number-field-picker:hover .phone-number-field-picker-button {
  color: var(--stannah-bordeux);
}

.phone-number-field-option-item.selected {
  background-color: var(--stannah-web-grey);
  color: #fff;
}

.phone-number-field-option-item.highlighted {
  background-color: var(--stannah-bordeux);
  color: #fff;
}

.phone-number-field-flag {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.phone-number-field-input {
  width: 100%;
  display: flex;

  & .phone-number-field-input-code-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--stannah-web-grey);
    font-size: 18px;
    padding: 16px 0 16px 0;
    line-height: 24px;
    font-weight: 500;
  }
  & input {
    border: none;
    width: 100%;
    border-radius: 6px;
    padding: 16px 20px 16px 8px;
    margin-top: 1px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: var(--stannah-dark-grey);
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }
  }
}

.phone-number-field-input input[disabled] {
  cursor: not-allowed;
}
