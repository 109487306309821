.userpanel {
  position: absolute;
  top: 72px;
  right: 20px;
  background-color: var(--stannah-white);
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 2px 0px rgba(0, 0, 0, 0.16);
  padding: 12px;
  border-radius: 6px;
  width: 250px;
  z-index: 2;

  & button {
    border: none;
    background: none;
    cursor: pointer;
  }
}
