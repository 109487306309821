.card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  background-color: var(--stannah-white);
  border: 1px solid var(--stannah-web-pale-grey);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  color: var(--stannah-dark-grey);
  width: 523px;

  & > header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    padding-bottom: 12px;

    & .card-title-area {
      flex-grow: 1;

      & > h1 {
        color: var(--stannah-dark-grey);
        font-size: 18px;
        box-sizing: border-box;
        font-weight: 700;
        margin-top: 0px;
        margin-bottom: 3px;
      }

      & > h2 {
        color: var(--stannah-web-grey);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin: 0px;
      }
    }

    & .card-button {
      flex-grow: 0;
      flex-shrink: 0;
      color: var(--stannah-teal);
      background-color: transparent;
      border: 0px;

      & :hover {
        cursor: pointer;
      }
    }

    & .card-refresh-button > svg {
      fill: var(--stannah-teal);
    }

    & .card-edit-button {
      display: flex;
    }
  }

  & > footer {
    width: 100%;
    padding: 24px 12px;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 18px;
    line-height: 24px;
    color: var(--stannah-web-grey);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  & > footer.card-footer-shadow {
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);
  }

  & header.card-title-area-gap {
    padding-bottom: 32px;
  }
}

.card-content {
  width: 100%;
  max-height: 1180px;
  box-sizing: border-box;
  overflow-y: auto;

  & > progress {
    margin: 24px;
  }
}

.card-normal {
  flex-shrink: 0;
  flex-grow: 0;
}

.card-double {
  width: 1058px;
  flex-grow: 0;
}

.card-wide {
  width: 100%;
}

@media (min-width: 960px) {
  .card-wide {
    width: 932px;
  }
  .card-double {
    width: 523px;
  }
}

@media (min-width: 1400px) {
  .card-wide {
    width: 1404px;
  }

  .card-double {
    width: 1058px;
  }
}

.card-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
}

.card-detail {
  display: flex;
  text-align: left;
  font-size: 18px;
  padding: 6px 24px;
  border-bottom: 1px solid var(--stannah-web-pale-grey);
  gap: 4px;
  height: 60px;
  align-items: center;
}

.card-col {
  display: flex;
  justify-content: start;
  max-width: 256px;
}

.card-detail-icon {
  margin-right: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  color: var(--stannah-web-grey);
}

.card-detail-titled-icon {
  fill: var(--stannah-web-grey);
}

.card-detail-titled-button {
  margin-left: 24px;
}

.card-detail-value {
  flex-grow: 1;
}

.card-detail-titled-value {
  font-weight: 500;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: end;
}

.card-group {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  & > h1 {
    color: var(--stannah-dark-grey);
    font-size: 18px;
    box-sizing: border-box;
    font-weight: 500;
    margin: 12px 24px 3px 24px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  & > h2 {
    color: var(--stannah-web-grey);
    font-size: 14px;
    line-height: 20px;
    margin: 0px 24px 24px 24px;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: 400;
  }
}

.card-address-info {
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  & > h1 {
    color: var(--stannah-dark-grey);
    font-size: 18px;
    box-sizing: border-box;
    font-weight: 800;
    margin: 0px 24px 24px 24px;
  }
}

.card-address-info-address li:first-child {
  font-weight: 600;
}

.card-address-info-content {
  display: flex;
  margin: 0px 24px;

  & > svg {
    width: 50px;
  }

  & > ul {
    padding: 0;
    margin-top: 0px;

    & > li {
      list-style-type: none;
    }
  }
}
