.device-details-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.device-details-form-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;

  & .device-details-form-serial-number {
    width: 333px;

    & .formfield-hint-text {
      width: 100%;
      text-wrap: wrap;
    }

    & input {
      width: 100%;
    }
  }

  & .device-details-form-nickname {
    & input {
      width: 100%;
    }
  }

  & .device-details-form-address-section-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
    margin-bottom: 10px;

    & input {
      width: 100%;
    }
  }
}

.device-details-form-date-picker-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  & .device-details-form-date-picker-label {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: var(--stannah-dark-grey);
    margin-bottom: 5px;
  }

  & .device-details-form-date-picker-container {
    width: 100%;
    position: relative;
  }

  & .device-details-form-date-picker {
    display: flex;
    align-items: center;
    line-height: 24px;
    font-weight: 500;
    color: var(--stannah-dark-grey);
    padding: 16px 20px;
    border: 2px solid var(--stannah-dark-grey);
    border-radius: 6px;

    & .react-datetime-picker__wrapper {
      border: none;
      height: 24px;
      & .react-datetime-picker__button {
        .react-datetime-picker__button__icon {
          stroke: var(--stannah-dark-grey);
          margin-right: 0;
        }
        &:hover .react-datetime-picker__button__icon,
        &:focus .react-datetime-picker__button__icon {
          stroke: var(--stannah-teal);
        }
      }
    }

    & .react-datetime-picker__calendar {
      position: absolute !important;
      left: 0;
      z-index: 1000;
      width: 100%;
      top: 100% !important;

      & .react-calendar {
        border: 2px solid var(--stannah-dark-grey);
        border-radius: 8px;
      }
    }
  }
}

.device-details-form-section-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--stannah-dark-grey);
  margin-bottom: 20px;
}
