#app-container {
  flex: 1 1 auto;
  display: flex;
  background-size: 100%;
}

.app-container-content {
  margin-top: 10px;
  margin-left: 24px;
  margin-right: 40px;
  position: relative;
}


.active-status {
  display: inline-block;
  border-radius: 24px;
  padding: 2px 14px;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  background-color: rgba(153, 188, 0, 0.5);
  color: var(--stannah-ok);
}

.paused-status {
  display: inline-block;
  border-radius: 24px;
  padding: 2px 14px;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  background-color: var(--stannah-support-orange);
  color: var(--stannah-bordeux);
}

.inactive-status {
  display: inline-block;
  border-radius: 24px;
  padding: 2px 14px;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  background-color: var(--stannah-web-pale-grey);
  color: var(--stannah-black);
}

.bullet-active::before {
  content: '';
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: var(--stannah-support-green);
}


.bullet-paused::before {
  content: '';
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: var(--stannah-support-orange);
}


.bullet-inactive::before {
  content: '';
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: var(--stannah-web-pale-grey);
}