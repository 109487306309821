#login {
  flex: 1 1 auto;
  display: flex;
  background-size: cover;
  background-image: url("../../assets/Background.svg"),
    radial-gradient(
      133.31% 137.91% at 91.14% 105.2%,
      #f94949 15.01%,
      #cd202c 54.38%,
      #790000 89.6%
    );
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  align-items: center;
}

.login-content {
  width: 567px;
  height: 534px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--stannah-pink);
  padding: 30px 50px 50px 50px;
}

.login-content-form-title {
  color: var(--stannah-bordeux);
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  padding-bottom: 12px;
  padding-top: 20px;
}

.login-content-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-content-form-subtitle {
  color: var(--stannah-mid-grey);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 100px;
}

#login-microsoft-link {
  text-decoration: none;
  color: var(--stannah-white);
}
