.line-graph {
  padding: 28px 24px 57px 24px;
  border-radius: 6px;
  border: 1px solid var(--stannah-web-pale-grey);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}

.line-graph-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--stannah-bordeux);
  padding-bottom: 5px;
}

.line-graph-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-mid-grey);
  padding-bottom: 14px;
}
