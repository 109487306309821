.tenant-detail-contacts {
  width: 100%;

  & > h2 {
    color: var(--stannah-dark-grey);
    font-size: 18px;
    box-sizing: border-box;
    font-weight: 800;
    margin: 24px 24px 12px 24px;
  }
}

.tenant-detail-contacts-item {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.tenant-detail-contacts-item-area {
  display: flex;
  flex-direction: row;
}

.tenant-detail-contacts-item-content {
  display: flex;
  width: 100%;
  gap: 12px;
  font-size: 18px;
  line-height: 24px;
  color: var(--stannah-neutral-dark-grey);
  text-align: left;
  align-items: center;

  & > svg {
    padding: 10px;
  }

  & > h3 {
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
  }

  & > h4 {
    margin: 0px;
    margin-left: auto;
    margin-right: 20px;
    font-size: 18px;
  }
}

.tenant-detail-contacts-item-line {
  height: 1px;
  width: 100%;
  margin-top: 12px;
  background-color: var(--stannah-web-pale-grey);
}
