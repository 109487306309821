.topPanel {
  position: absolute;
  top: 72px;
  right: 40px;
  background-color: var(--stannah-white);
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px -1px 2px 0px rgba(0, 0, 0, 0.16);
  padding-top: 20px;
  border-radius: 6px;
  width: 450px;
  z-index: 2;

  & button {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.topPanel-content {
  max-height: 80vh;
  overflow-y: auto;
  margin-right: 5px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.topPanel-content::-webkit-scrollbar {
  width: 4px;
}

.topPanel-content::-webkit-scrollbar-track {
  background: var(--stannah-web-light-grey);
  border-radius: 10px;
}

.topPanel-content::-webkit-scrollbar-thumb {
  background: var(--stannah-web-light-grey);
  border-radius: 10px;
  transition: var(--stannah-web-light-grey) 0.3s;
}

.topPanel-content::-webkit-scrollbar-thumb {
  background: transparent;
}

.topPanel-content:hover::-webkit-scrollbar-thumb,
.topPanel-content:active::-webkit-scrollbar-thumb {
  background: var(--stannah-web-grey);
}

.topPanel-content::-webkit-scrollbar-button {
  display: none;
}

.topPanel-header {
  border-bottom: 1px solid var(--stannah-web-pale-grey);
  padding-bottom: 14px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.topPanel-header-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--stannah-dark-grey);
}

.topPanel-header-delete-button {
  text-decoration: underline;
  color: var(--stannah-error);
}

.topPanel-header-delete-button[disabled] {
  color: grey;
  cursor: not-allowed;
}

.topPanel-items {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--stannah-dark-grey);

  & > .topPanel-item:not(:last-child) {
    border-bottom: 1px solid var(--stannah-web-pale-grey);
  }
}

.topPanel-item {
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
}
