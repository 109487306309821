.tenant-form-contacts-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  & form {
    height: 100%;
    display: flex;
  }
}

.tenant-form-contacts-page-inputs {
  flex-grow: 1;
  overflow-y: auto;
}

.tenant-form-contacts-page-button-panel {
  bottom: 0px;
  padding-top: 20px;
  height: 90px;
  flex-grow: 0;
  display: flex;
  gap: 20px;
  justify-content: flex-end;

  & button {
    display: inline-block;
  }
}

.tenant-form-contacts-page-creation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
  padding-bottom: 32px;

  & > div {
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    color: var(--stannah-web-grey);

    & > h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 800;
      margin-bottom: 0px;
      color: var(--stannah-dark-grey);
    }

    & > h4 {
      font-size: 14px;
      line-height: 20px;
      margin-top: 0px;
      color: var(--stannah-web-grey);
    }
  }

  & > button {
    float: right;
    text-wrap: nowrap;
  }
}

.tenant-form-contacts-page-creation-input-row {
  display: flex;
  gap: 10px;

  & input[name="displayName"] {
    width: 100%;
  }

  & .tenant-form-contacts-page-phone {
    width: 100%;
  }
}

.tenant-form-contacts-page-emergency-row {
  display: flex;
  gap: 10px;
  padding-right: 54px;

  & input[name="emergencyContact"] {
    width: 100%;
  }

  & input[name="phoneNr"] {
    width: 100%;
  }
}

.tenant-form-contacts-page-delete-btn {
  top: 22px;
  left: 12px;
  border: none;
  background-color: transparent;
  margin: auto;
  padding: 30px 10px 10px 10px;

  &:hover {
    cursor: pointer;
  }

  & .delete-icon {
    width: 24px;
  }
}
