.header-content {
  width: 100%;
  margin-bottom: 32px;
}

.header-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: var(--stannah-bordeux);
  margin-bottom: 20px;
}

.header-line {
  width: 100%;
  border-bottom: 2px solid var(--stannah-grey);
}
