.tab {
  width: 100%;
}

.tab-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 2px solid var(--stannah-putty);
}

.tab-list-item {
  width: 156px;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.tab-list-item.active {
  border-bottom: 4px solid var(--stannah-putty);
  color: var(--stannah-bordeux);
}

.tab-list-item.inactive {
  color: var(--stannah-mid-grey);
}

.tab-content {
  margin-top: 36px;
}
