.navbar-icon {
  margin-left: 12px;
  margin-right: 12px;
}

.navbar-icon-wrapper {
  display: flex;
  align-items: center;
}

.navbar-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 14px;
  color: var(--stannah-bordeux);
  background-color: transparent;
}

.navbar-menu-item:hover {
  border-radius: 6px;
  color: var(--stannah-invert-text);
  background-color: var(--stannah-bordeux);
}

.navbar,
.navbar-collapsed {
  width: 260px;
  padding: 12px;
  background-color: var(--stannah-pink); 
  color: var(--stannah-bordeuax);
  flex-shrink: 0;
  position: relative;
  font-size: 18px;

  & #navbar-collapse {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 26px;
    right: -12px;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: var(--stannah-grey);
    border-radius: 8px;
    cursor: pointer;
  }

  & #logo {
    width: 60%;
    display: flex;
    justify-self: center;
  }

  & menu {
    list-style-type: none;
    padding-left: 0;

    & li {
      width: 100%;
      height: 56px;
    }

    & a {
      text-decoration: none;
      width: 100%;
      height: 100%;
    }
  }
}

.navbar-collapsed {
  width: 100px;
  padding-top: 28px;
  
  & span:last-child {
    display: none;
  }
 
  & #logo {
 padding-bottom: 9px;
  } 
}