.toggle-switch-container {
  display: flex;
}

.toggle-switch-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--stannah-dark-grey);
}

.toggle-switch-btn {
  margin-left: auto;
  background-color: #b7b9ba;
  border: 1px solid #aaa;
  border-radius: 99px;
  width: 60px;
  height: 30px;
  transition:
    background-color 0.1s ease,
    border-color 0.2s ease;
  cursor: pointer;
  position: relative;
}

.toggle-switch-btn[disabled] {
  background-color: var(--stannah-grey);
  border: none;
}

.toggle-switch-thumb {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 99px;
  transform: translateX(0);
  transform: left 0.15s ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.toggle-switch-on {
  background-color: var(--stannah-teal);
}

.toggle-switch-off {
  background-color: var(--stannah-web-grey);
}

.toggle-switch-text {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
}

.toggle-switch-on-card {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  background-color: rgba(7, 68, 77, 0.7);
}
.toggle-switch-off-card {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 10px;
  background-color: rgba(122, 117, 117, 0.7);
}

.toggle-switch-text.on {
  color: var(--stannah-teal);
}

.toggle-switch-text.off {
  color: var(--stannah-mid-grey);
}

.toggle-switch-btn:hover {
  border-color: #6f6f6f;
}

.toggle-switch-on .toggle-switch-thumb,
.toggle-switch-on-card .toggle-switch-thumb {
  left: calc(50px - 15px);
}

.toggle-switch-checked-value {
  margin-left: 20px;
}
