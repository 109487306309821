.network-info-card-current-status-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-web-grey);
  margin-bottom: 6px;
}

.network-info-card-current-status-content {
  width: 100%;
  border-radius: 6px;
  height: 60px;
  padding-right: 22px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.network-info-card-current-status-content.connected {
  background: rgba(154, 188, 0, 0.11);
}

.network-info-card-current-status-content.disconnected {
  background: rgba(255, 0, 0, 0.11);
}

.network-info-card-current-status-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.network-info-card-current-status-info-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--stannah-dark-grey);
}

.network-info-card-current-status-details {
  text-align: right;
}

.network-info-card-current-status-details-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-dark-grey);
}

.network-info-card-current-status-details-info {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--stannah-dark-grey);
}
