.context-menu {
  height: 50px;
  min-width: 50px;
  position: relative;
  display: inline-block;
  overflow: visible;

  & > button {
    font-size: 17px;
    font-weight: bold;
    border: none;
    outline: none;
    padding: 14px 16px;
    background-color: inherit;
    margin: 0;
  }
}

.context-menu-items {
  display: none;
  position: absolute;
  background-color: var(--stannah-web-light-grey);
  right: 0px;
  min-width: 250px;
  max-width: 400px;
  min-height: 20px;
  padding: 6px 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  z-index: 10;
  font-size: 18px;
}

.context-menu:hover .context-menu-items {
  display: block;
  cursor: pointer;
}

.context-menu-item {
  float: none;
  color: var(--stannah-black);
  text-decoration: none;
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 12px 24px;
  gap: 24px;

  & .context-menu-item-icon {
    width: 24px;
    height: 24px;
  }
}

.context-menu-item.disabled {
  pointer-events: none;
  color: var(--stannah-web-grey);
  background-color: var(--stannah-web-light-grey);
}
