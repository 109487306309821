.no-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.no-items-icons {
  padding: 20px;
}

.no-items-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  color: var(--stannah-black);
  text-align: center;
}

.no-items-subtitle {
  font-size: 18px;
  line-height: 24px;
  color: var(--stannah-black);
  text-align: center;
}

.no-items-button {
  padding-top: 30px;
  padding-bottom: 15px;
}
