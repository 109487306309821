.lift-details-contact-row {
  display: flex;
  justify-content: space-between;
}

.lift-details-other-contacts-banner {
  margin-top: 5px;
  color: var(--stannah-web-grey);
  display: flex;
  justify-content: center;
}

.one-below-another {
  display: flex;
  flex-direction: column;
}

.lift-details-section {
  margin-bottom: 30px;
}

.lift-details-secondary-text {
  color: var(--stannah-web-grey);
  font-size: 14px;
  line-height: 20px;
}

.info-titles {
  display: flex;
  justify-content: space-between;
}

.lift-details-col {
  flex: 1;
}

#dependable-contacts-edit-title {
  font-size: 1em;
}

#optional-text {
  justify-content: right;
}
