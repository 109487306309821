.one-below-another {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.lift-contact-form-subtitle {
  & h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 0px;
    color: var(--stannah-dark-grey);
  }

  & h4 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    font-weight: 400;
    color: var(--stannah-web-grey);
  }
}

.lift-contact-form-row-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--stannah-dark-grey);
}

.lift-contact-form-row-hint {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-web-grey);
  margin-right: 3px;
}
