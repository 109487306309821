.employee-form {
  height: 100%;
  display: flex;
  flex-grow: 1;

  & form {
    height: 100%;
    flex-grow: 1;
  }
}

.employee-form-role-page,
.employee-form-employee-page {
  display: flex;
  height: 100%;
  flex-direction: column;

  & input[name="id"] {
    width: 200px;
  }

  & .employee-form-role-delete-role-button {
    color: var(--stannah-teal);
    background-color: var(--stannah-white);
    padding: 12px;
  }

  & .employee-form-delete-emails-button {
    color: var(--stannah-teal);
    background-color: var(--stannah-white);
    padding: 12px;
  }

  & svg {
    width: 24px;
    height: 24px;
  }
}

.employee-form-edit-hint {
  padding-bottom: 20px;
}

.employee-form-role-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
  margin-bottom: 5px;
}

.employee-form-inputs {
  flex-grow: 1;
}

.employee-form-creation-row {
  display: flex;
  align-items: center;

  & h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    margin-bottom: -10px;
    color: var(--stannah-dark-grey);
  }

  & p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-right: 80px;
    color: var(--stannah-web-grey);
  }

  & h4 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    color: var(--stannah-mid-grey);
  }

  & button {
    float: right;
  }
}

.employee-form-edit-email {
  & input {
    width: 622px;
  }
}

.employee-form-role-employees {
  display: flex;
  gap: 10px;

  & .employee-form-role-delete-role-button {
    border: none;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      color: #bfbfbf;
      cursor: auto;
    }
  }

  & .picker {
    width: 100%;
  }
}

.employee-form-role-form {
  margin-top: 18px;
}

.employee-form-delete-emails-button {
  border: none;
}

.employee-form-delete-emails-button-invisilble {
  visibility: hidden;
}

.employee-form-email {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 10px;
}

.employee-form-email-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
  margin-bottom: 5px;
}

.employee-form-email-input-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 18px 20px;
  color: var(--stannah-dark-grey);
  gap: 10px;
  min-height: 60px;
  margin-bottom: 4px;
  font-size: 18px;
  border-radius: 6px;
  background-color: var(--stannah-white);
  border: 2px solid var(--stannah-mid-grey);
}

.employee-form-email-input {
  border: none;
  outline: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  flex-grow: 1;
  padding: 0;
}

.employee-form-email-input-hint {
  height: 20px;
  top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--stannah-web-grey);
  text-align: left;
}

.employee-form-errors {
  flex-grow: 1;
  padding: 20px;
  align-content: end;
  font-size: 14px;
  color: var(--stannah-web-grey);
}

.employee-form-footer {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--stannah-web-grey);
}
