.side-modal-button-bar {
  display: flex;
  padding: 50px 60px 50px 0; 
  justify-content: end;
  gap: 20px;

  & .side-modal-button-bar-with-paging {
    margin-right: auto;
  }

  & #submit_button {
    color: var(--stannah-white);
    background-color: var(--stannah-teal);
  }

  & #submit_button:disabled {
    color: var(--stannah-web-grey);
    background-color: var(--stannah-web-light-grey);
    border: 2px solid var(--stannah-web-light-grey);
  }

}