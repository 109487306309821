.sip-settings-form {
  display: flex;
  height: 100%;
  flex-direction: column;

  & form {
    height: 100%;
    display: flex;
  }
}

.sip-settings-form-lines {
  padding-top: 5px;
  flex-grow: 1;
  overflow-y: auto;

  & .formfield input {
    width: 100%;
    margin-bottom: 14px;
  }

  & .phone-number-field {
    margin-top: 20px;
  }
}
