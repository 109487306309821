form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.device-settings-form-row-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--stannah-dark-grey);
}
.device-settings-form-subtitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 51px;
  margin-bottom: 20px;
  color: var(--stannah-dark-grey);
}

.device-settings-form-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 24px 24px;
  border-bottom: 1px solid var(--stannah-web-pale-grey);

  & .device-settings-form-edit-column-switch {
    padding: 24px 24px;
  }
}

.device-settings-form-picker-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--stannah-dark-grey);
}

.device-settings-form-edit-column {
  display: flex;
  justify-content: end;

  & input {
    width: 100px;
  }

  & input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  & input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  & input[type="number"]::-moz-inner-spin-button,
  input[type="number"]::-moz-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  & input[type="number"]::-ms-clear,
  input[type="number"]::-ms-reveal {
    display: none;
  }
}
