.picker {
  min-height: 60px;
  min-width: 50px;
  overflow: visible;
  font-size: 18px;
  margin-bottom: 15px;
  position: relative;

  & > h1 {
    font-size: 24px;
    color: var(--stannah-black);
  }

  & > h2 {
    font-size: 16px;
    color: var(--stannah-black);
  }
}

.picker .dropbutton {
  font-size: 17px;
  font-weight: bold;
  border: none;
  outline: none;
  padding: 14px 16px;
  background-color: inherit;
  margin: 0;
}

.picker-disabled {
  background-color: var(--stannah-web-light-grey);
  cursor: not-allowed;
  border-radius: 8px;

  & .picker-button {
    border: 2px solid transparent;
  }
}

.picker-button {
  background-color: transparent;
  border: 2px solid var(--stannah-mid-grey);
  border-radius: 8px;
  text-align: left;
  color: var(--stannah-mid-grey);
  padding: 18px 20px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  & svg {
    color: var(--stannah-teal);
  }
}

.required-warning {
  color: var(--stannah-error);
}

.picker-button:disabled {
  pointer-events: none;
}

.picker-content {
  display: none;
  text-align: left;
  width: 100%;
  border: 2px solid var(--stannah-black);
  background-color: #fff;
  border-radius: 8px;
  min-width: 160px;
  z-index: 1;
  position: absolute;
}

.picker-content button {
  color: var(--stannah-black);
  background-color: #fff;
  width: 100%;
  text-align: left;
  border-radius: 8px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;

  &:disabled {
    display: none;
  }
}

.picker-content button:hover {
  background-color: var(--stannah-bordeux);
  color: #fff;
}

.picker:hover .picker-content {
  display: block;
}

.picker .picker-button:not(:disabled):hover {
  color: var(--stannah-bordeux);
}
