.formfield {
  width: 100%;
  text-align: left;
  font-size: 18px;
  color: var(--stannah-dark-grey);
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
}

.formfield-inputs {
  display: flex;
}

.formfield label {
  top: 1px;
  left: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.label-disabled {
  color: var(--stannah-mid-grey);
}

.formfield input {
  height: 60px;
  font-size: 18px;
  padding: 12px 20px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--stannah-white);
  border: 2px solid var(--stannah-mid-grey);
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invalid-input input {
  border-color: var(--stannah-error) !important;
}

.formfield input:invalid {
  border-color: var(--stannah-error);
}

.formfield input[disabled] {
  border: 2px solid transparent;
  background-color: var(--stannah-web-light-grey);
  cursor: not-allowed;
}

.formfield-hint-text {
  height: 20px;
  top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--stannah-web-grey);
  text-align: left;
}

.formfield-optional {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--stannah-web-grey);
  text-align: right;
  display: inline-block;
  padding-left: 100px;
  font-weight: 300;
}

.formfield-show-input-button {
  margin-top: 10px;
  margin-left: 10px;
  border: none;
  color: var(--stannah-web-grey);

  &:hover {
    cursor: pointer;
  }
}

.formfield-extension {
  display: flex;
  margin: auto 10px;
}
