.tenant-form-details-page {
  display: flex;
  height: 100%;
  flex-direction: column;

  & input[name="id"] {
    width: 200px;
  }

  & input[name="name"] {
    width: 467px;
  }

  & input[name="businessId"] {
    width: 200px;
  }

  & input[name="address"] {
    width: 467px;
  }

  & .tenant-form-details-page-phone-row {
    width: 467px;
  }

  & input[name="email"] {
    width: 467px;
  }
}

.tenant-form-details-page-inputs {
  flex-grow: 1;
  overflow-y: auto;
}
